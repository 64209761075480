<template>
  <div class="full-width full-height">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
    <!--  <iframe id="child" src="http://127.0.0.1:8848/"></iframe> -->
  </div>
  <!--<div class="home-wrap">
     <div class="full-width home-inner flex">
      <div class="h-i-l">
        <home-left></home-left>
      </div>
      <div class="h-i-m flex-one m-l-sm m-r-sm">
        <home-main></home-main>
      </div>
      <div class="h-i-r">
        <home-right></home-right>
      </div>
    </div> 
  </div>-->
</template>

<script>
import { mapActions } from 'vuex';
// import Header from '@/components/Header';
// import HomeLeft from './components/HomeLeft';
// import HomeRight from './components/HomeRight';
// import HomeMain from './components/HomeMain';
/* window.onload = function() {
  console.log('set key value......................');
  window.frames[0].postMessage(
    JSON.stringify({ type: 'SET', key: 'key', value: 'value' }),
    '*'
  );
}; */
export default {
  name: 'Home',
  components: {
    // Header,
    // HomeLeft,
    // HomeRight,
    // HomeMain,
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions(['logout']),
   /* logoutHandle () {
      this.logout();
    },
    openApi() {}, */
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* .home-wrap {
  background: #0f182d url('../../assets/home/home-bg.jpg') bottom center
    no-repeat;
  width: 100%;
  height: 100%;
  padding: 0 10px 10px;
}
.home-inner {
  border: 1px solid #4a6080;
  border-top: none;
  height: calc(100% - 67px);
  padding: 0 15px 20px;
}
.home-inner .h-i-l,
.home-inner .h-i-r {
  width: 360px;
  height: 100%;
} */
</style>
